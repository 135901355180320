/* Main */
@import url("https://fonts.googleapis.com/css2?family=Dosis:wght@400;700&display=swap");

button {
  font-family: "Dosis", sans-serif;
  font-weight: 700;
}

b {
  font-weight: 700;
}

.main-content {
  display: grid;
  grid-template-rows: 1fr 1fr;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  width: 100vw;
  height: calc(100vh - 2rem);
  color: white;
  font-family: "Dosis", sans-serif;
}

.hidden {
  opacity: 0;
}

/* Header */
.header {
  height: 2rem;
}

.settings-btn {
  background-color: transparent;
  border: none;
  color: white;
  margin: 1rem;
  padding: 0.2rem;
  font-size: 1rem;
}

/* Backdrop */
.backdrop {
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

/* Alert */
.alert {
  text-align: center;
  padding: 0.5rem 0.5rem 0.5rem 1.5rem;
  background-color: white;
  color: black;
  font-weight: 700;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  justify-content: center;
  align-items: center;
}

.alert-btn {
  float: right;
  background-color: transparent;
  padding: 0;
  color: black;
}

.alert-btn:focus {
  background-color: transparent;
}

.close-icon {
  vertical-align: 2rem;
}

/* Modal */
.modal {
  width: 50vw;
  color: black;
  background-color: white;
  border-radius: 10px;
  padding: 2rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

.modal-title {
  font-weight: 700;
  margin-top: 0;
}

.modal-btn {
  padding: 0.4rem;
  width: 100%;
  text-transform: uppercase;
  color: black;
}

.modal-content form {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 2rem;
}

/* Response From The Void */
.void-response {
  width: clamp(60%, 60vw, 90%);
  margin: 0 auto;
  text-align: center;
  font-size: 4rem;
  font-weight: 700;
  padding: 2rem 1.5rem;
  transition: 0.3s ease-in-out all;
  word-wrap: break-word;
  overflow-wrap: break-word;
}

@media screen and (max-width: 535px) {
  .void-response {
    font-size: 2.5rem;
  }
}

@media screen and (max-width: 365px) {
  .void-response {
    font-size: 2rem;
  }
}

/* The Scream TM */
.scream-box {
  align-self: start;
  min-width: 60vw;
  width: 100%;
  transition: 0.3s ease-in-out all;
  display: flex;
  flex-direction: column;
  gap: 0.7rem;
  padding: 2rem;
}

.scream-box textarea {
  padding: 1rem;
  text-align: center;
  text-transform: uppercase;
  resize: none;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 5px;
  font-family: "Dosis", sans-serif;
  font-size: 1.5rem;
}

.scream-box textarea:focus {
  outline: transparent;
}

.scream-content {
  width: 100%;
  min-height: 10rem;
}

.scream-btn {
  text-transform: uppercase;
  padding: 0.4rem;
  background-color: #01285c;
  color: white;
}
